import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const routes = [
	{
		path: '/',
		name: 'Home',
		component: () => import('@/views/index'),
		meta: {
			keepAlive: false,
		},
	},
	{
		path: '/product',
		name: 'product',
		component: () => import('@/views/product'),
		meta: {
			keepAlive: false,
		},
	},
	{
		path: '/solution',
		name: 'solution',
		component: () => import('@/views/solution'),
		meta: {
			keepAlive: false,
		},
	},
	{
		path: '/case',
		name: 'case',
		component: () => import('@/views/case'),
		meta: {
			keepAlive: false,
		},
	},
	{
		path: '/news',
		name: 'news',
		component: () => import('@/views/news'),
		meta: {
			keepAlive: false,
		},
	},
	{
		path: '/about',
		name: 'about',
		component: () => import('@/views/about'),
		meta: {
			keepAlive: false,
		},
	},
	{
		path: '/deviceDetail',
		name: 'deviceDetail',
		component: () => import('@/views/devicesDetail'),
		meta: {
			keepAlive: false,
		},
	},
	{
		path: '/softwareDetail',
		name: 'softwareDetail',
		component: () => import('@/views/softwareDetail'),
		meta: {
			keepAlive: false,
		},
	},
	{
		path: '/solutionDetail',
		name: 'solutionDetail',
		component: () => import('@/views/solutionDetail'),
		meta: {
			keepAlive: false,
		},
	},
	{
		path: '/newsDetail',
		name: 'newsDetail',
		component: () => import('@/views/newsDetail'),
		meta: {
			keepAlive: false,
		},
	},
	{
		path: '/contactUs',
		name: 'contactUs',
		component: () => import('@/views/contactUs'),
		meta: {
			keepAlive: false,
		},
	},
]

const router = new VueRouter({
	routes: routes,
	scrollBehavior(to, from, savedPosition) {
		if (to.hash) {
			return {
				selector: to.hash,
				behavior: 'smooth' // 可以是 'smooth' 或 'auto'
			};
		}
	}
})

export default router